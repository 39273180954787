/** @format */

import React from "react"
import {Box, Divider, Grid} from "@mui/material"
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
export default function PrivacyPolicy() {
  return (
    <div className="terms-and-condition">
      <Box className="privacy-policy-container" sx={{flexGrow: 1}}>
        <Grid container spacing={1}>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
          <Grid item xs={10} md={4}>
            <div className="privacy-policy-main-header-text">
              Privacy Policy
            </div>
            <div className="privacy-policy-normal-text">
              At Chandukaka Saraf Jewels (CSJ), we value your trust and are
              committed to protecting your privacy. This Privacy Policy outlines
              how we collect, use, disclose, and safeguard your information on
              our website. By using our website, you consent to the practices
              described in this policy.
            </div>
            <hr />
          </Grid>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{flexGrow: 1}}>
        <Grid container spacing={1}>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
          <Grid item xs={10} md={4}>
            <div className="privacy-policy-header-text">
              1. Information We Collect
            </div>
            <div className="privacy-policy-normal-text">
              We may collect the following types of information:
              <div className="mt-3 d-flex">
                <div>
                  <ArrowRightIcon fontSize="medium" />
                </div>{" "}
                Personal Information:Such as name, email address, phone number,
                and shipping/billing address when you place an order or sign up
                for our services.
              </div>
              <div className=" d-flex">
                <div>
                  <ArrowRightIcon fontSize="medium" />
                </div>
                Non-Personal Information:Such as browser type, IP address, and
                website usage data to improve your online experience.
              </div>
            </div>
            <hr />
          </Grid>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{flexGrow: 1}}>
        <Grid container spacing={1}>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
          <Grid item xs={10} md={4}>
            <div className="privacy-policy-header-text">
              2. How We Use Your Information
            </div>
            <div className="privacy-policy-normal-text">
              We use the information collected to:
              <div className=" mt-3 d-flex">
                <div>
                  <ArrowRightIcon fontSize="medium" />
                </div>{" "}
                Process orders and deliver products/services.
              </div>
              <div className=" d-flex">
                <div>
                  <ArrowRightIcon fontSize="medium" />
                </div>
                Provide customer support.
              </div>
              <div className=" d-flex">
                <div>
                  <ArrowRightIcon fontSize="medium" />
                </div>
                Improve website functionality and user experience.
              </div>
              <div className=" d-flex">
                <div>
                  <ArrowRightIcon fontSize="medium" />
                </div>
                Send promotional communications (if you have opted in).
              </div>
            </div>
            <hr />
          </Grid>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{flexGrow: 1}}>
        <Grid container spacing={1}>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
          <Grid item xs={10} md={4}>
            <div className="privacy-policy-header-text">
              3. Disclosure of Information
            </div>
            <div className="privacy-policy-normal-text">
              We do not sell or rent your personal information to third parties.
              However, we may share information with trusted partners for
              operational purposes, including:
              <div className=" mt-3 d-flex">
                <div>
                  <ArrowRightIcon fontSize="medium" />
                </div>{" "}
                Delivery services for order fulfillment.
              </div>
              <div className=" d-flex">
                <div>
                  <ArrowRightIcon fontSize="medium" />
                </div>
                Payment gateways for secure transactions.
              </div>
              <div className=" d-flex">
                <div>
                  <ArrowRightIcon fontSize="medium" />
                </div>
                Legal authorities, if required by law.
              </div>
            </div>
            <hr />
          </Grid>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{flexGrow: 1}}>
        <Grid container spacing={1}>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
          <Grid item xs={10} md={4}>
            <div className="privacy-policy-header-text">4. About NSJ</div>
            <div className="privacy-policy-normal-text">
              NSJ (Niyam Sagar Ji) is an integral part of the Chandukaka Saraf
              Jewels family, committed to offering the same quality and trust
              that CSJ is known for. Operating independently under a franchise
              agreement, NSJ ensures adherence to our privacy and service
              standards while running its jewellery store in Nanded.
            </div>
            <hr />
          </Grid>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{flexGrow: 1}}>
        <Grid container spacing={1}>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
          <Grid item xs={10} md={4}>
            <div className="privacy-policy-header-text">
              5. Our Relationship with NSJ
            </div>
            <div className="privacy-policy-normal-text">
              NSJ is a Limited Liability Partnership firm and a franchise of
              Chandukaka Saraf Jewels. NSJ currently operates a jewellery store
              in Nanded. As part of our franchise network, NSJ follows the same
              standards and commitments to customer privacy as CSJ. When you
              engage with NSJ, your information may be shared with NSJ for
              service delivery purposes.
            </div>
            <hr />
          </Grid>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{flexGrow: 1}}>
        <Grid container spacing={1}>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
          <Grid item xs={10} md={4}>
            <div className="privacy-policy-header-text">6. Data Security</div>
            <div className="privacy-policy-normal-text">
              We implement robust measures to protect your data against
              unauthorized access, alteration, or disclosure. However, no system
              is entirely secure, and we cannot guarantee the complete safety of
              your data.
            </div>
            <hr />
          </Grid>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{flexGrow: 1}}>
        <Grid container spacing={1}>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
          <Grid item xs={10} md={4}>
            <div className="privacy-policy-header-text">7. Your Rights</div>
            <div className="privacy-policy-normal-text">
              You have the right to:
              <div className=" mt-3 d-flex">
                <div>
                  <ArrowRightIcon fontSize="medium" />
                </div>
                Access your personal data.
              </div>
              <div className=" d-flex">
                <div>
                  <ArrowRightIcon fontSize="medium" />
                </div>
                Request correction or deletion of your data.
              </div>
              <div className=" d-flex">
                <div>
                  <ArrowRightIcon fontSize="medium" />
                </div>
                Opt-out of marketing communications.
              </div>
            </div>
            <hr />
          </Grid>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{flexGrow: 1}}>
        <Grid container spacing={1}>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
          <Grid item xs={10} md={4}>
            <div className="privacy-policy-header-text">
              8. Changes to This Privacy Policy
            </div>
            <div className="privacy-policy-normal-text">
              CSJ reserves the right to update this Privacy Policy at any time.
              Changes will be posted on this page, and your continued use of our
              website constitutes your acceptance of the updated terms.
            </div>
            <hr />
          </Grid>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{flexGrow: 1}}>
        <Grid container spacing={1}>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
          <Grid item xs={10} md={4}>
            <div className="privacy-policy-header-text">9. Contact Us</div>
            <div className="privacy-policy-normal-text">
              For questions or concerns regarding this Privacy Policy, please
              contact us at:
              
              <div className=" d-flex">
                {/* <div>
                  <ArrowRightIcon fontSize="medium" />
                </div> */}
               Chandukaka Saraf Jewels
              </div> 
              <div className=" d-flex">
                {/* <div>
                  <ArrowRightIcon fontSize="medium" />
                </div> */}
                Email: care@csjewels.com
              </div> 
              <div className=" d-flex">
                {/* <div>
                  <ArrowRightIcon fontSize="medium" />
                </div> */}
                Phone: 7969991827

              </div> 
            </div>
            <hr />
          </Grid>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{flexGrow: 1}}>
        <Grid container spacing={1}>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
          <Grid item xs={10} md={4}>
            <div className="privacy-policy-normal-text">
            We value your trust and are committed to maintaining the confidentiality of your information.
            </div>
            <hr />
          </Grid>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
        </Grid>
      </Box>
    
    </div>
  )
}
